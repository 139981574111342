import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { ModalOptions } from 'modals';
import { ModalProgram, ModalAssociateEntity } from 'modals';

import { GenericScene } from 'scenes'

import { Section, Properties, InfosBar, Table, Empty, Aligner } from 'components';
import { Container, Button, Image, Flag, Message, Icon } from 'semantic-ui-react';

import { API } from 'services';

import { BreadcrumbHelper, Typeof, Functions } from 'utils';
import { EnumLocale } from 'enums';

const CATEGORIES_PER_PAGE = 10;

class ProgramScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      program: props.program,
      showEditModal: false,
      showDeleteModal: false,
      pending: false,
      categorySearchCriteria: '',
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleEditModalClose = this.handleEditModalClose.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);

    this.handleCategorySearch = this.handleCategorySearch.bind(this);

    this.handleCategorySelect = this.handleCategorySelect.bind(this);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Administration', '/');
    BreadcrumbHelper.push('Programmes', '/programs');
    BreadcrumbHelper.push(this.state.program.id);
    BreadcrumbHelper.push(`Programme #${this.state.program.id}`);
    BreadcrumbHelper.flush();
  }

  toggleEditModal() {
    this.setState({
      showEditModal: !this.state.showEditModal
    });
  }

  handleEditModalClose(edit) {

    if(Typeof.object(edit)) {

      this.setState({
        program: edit
      }, this.updateBreadcrumb);
    }

    //Hide modal
    this.toggleEditModal();
  }

  toggleDeleteModal() {

    //Show modal
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleDeleteModalClose(result, setProcessing) {

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      API.delete(`/programs/${this.props.program.id}`)
        .then(() => {

          //Go back to programs list
          this.props.history.push('/programs');
        });

    } else {

      //Hide modal
      this.toggleDeleteModal();
    }

  }
  handleCategorySearch(criteria) {

    this.setState({
      categorySearchCriteria: criteria
    });

  }

  handleCategorySelect(category) {
    this.props.history.push(`/categories/${category.id}`);
  }

  render() {

    const { program } = this.state;
    const { categories } = this.props;

    let properties = [
      {
        name: 'Nom',
        value: () => {

          return (
            <Aligner direction="vertical" vertical="bottom" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = program.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>

                    {localization != null && Typeof.string(localization.name) ? (
                      <span>{localization.name}</span>
                    ) : (
                      <Empty />
                    )}

                    <Flag name={locale.flag.toLowerCase()} />
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        name: 'Ancien nom',
        value: () => {

          return (
            <Aligner direction="vertical" vertical="bottom" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = program.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>

                    {localization != null && Typeof.string(localization.old_name) ? (
                      <span>{localization.old_name}</span>
                    ) : (
                      <Empty />
                    )}

                    <Flag name={locale.flag.toLowerCase()} />
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        name: 'Logo',
        value: () => {

          if(!Typeof.string(program.logo)) {
            return <Empty />;
          }

          return <Image className="logo" src={Functions.base64ToImage(program.logo)} />;
        }
      },
      {
        name: 'Nombre de cycles de décompression',
        value: () => {
          return program.nb_dec
        }
      },
      {
        name: 'Tempo attente première traction',
        value: () => {
          return program.dec_pre
        }
      },
      {
        name: 'Tempo attente étirement',
        value: () => {
          return program.dec_eti
        }
      },
      {
        name: 'Tempo attente maintien',
        value: () => {
          return program.dec_mai
        }
      },
      {
        name: 'Nombre de cycles d\'oscillation',
        value: () => {
          return program.nb_osc
        }
      },
      {
        name: 'Tempo demarrage',
        value: () => {
          return program.osc_dem
        }
      },
      {
        name: 'Tempo travail',
        value: () => {
          return program.osc_tra
        }
      },
      {
        name: 'Tempo récupération',
        value: () => {
          return program.osc_rec
        }
      },
      {
        name: 'Grande vitesse ?',
        value: () => {
          return program.is_vtg ? "Oui" : "Non"
        }
      }
    ];

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (category) => category.id,
        fit: true
      },
      {
        id: 'logo',
        header: 'Logo',
        render: (category) => {

          if(category.logo != null) {
            return <Image src={Functions.base64ToImage(category.logo)} className="logo" />
          } else {
            return <Empty />
          }
        },
        fit: true
      },
      {
        id: 'name',
        header: 'Nom',
        value: (category) => {

          return category.localizations.map((localization) => {
            return localization.name
          });
        },
        render: (category) => {

          return (
            <Aligner direction="vertical" vertical="left" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = category.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>
                    <Flag name={locale.flag.toLowerCase()} />

                    {localization != null && Typeof.string(localization.name) ? (
                      <span>{localization.name}</span>
                    ) : (
                      <Empty />
                    )}
                  </span>
                )
              })}
            </Aligner>
          )
        }
      }
    ];

    const associatedCategories = categories.filter((category) => {
      return program.categories.includes(category.id);
    });

    return (
      <GenericScene>

        <Container id="program">

          <Section title="Propriétés" actions={[
            <Button icon="edit" circular onClick={this.toggleEditModal} />,
            <Button icon="trash" circular color="red" onClick={this.toggleDeleteModal} />
          ]}>

            <Properties properties={properties} />
          </Section>

        </Container>

        <Container>

          <Section title="Catégories associés" onSearch={this.handleCategorySearch} criteria={this.state.categorySearchCriteria}>

            {associatedCategories.length > 0 ? (

              <React.Fragment>

                <InfosBar mode="column">

                  {/* Number of associated categories */}
                  <span>{associatedCategories.length > 1 ? `${associatedCategories.length} catégories associées` : '1 catégorie associée'}</span>

                </InfosBar>

                {/* Rooms list */}
                <Table columns={columns}
                  criteria={this.state.categorySearchCriteria}
                  items={associatedCategories}
                  itemsPerPage={CATEGORIES_PER_PAGE}
                  onSelect={this.handleCategorySelect}
                  messageEmpty="Aucune catégorie ne correspond à votre recherche"
                  sort="id"
                  direction="ascending"
                />

              </React.Fragment>

            ) : (

              // No category associated
              <InfosBar mode="row">
                <span>Aucun catégorie associée</span>

              </InfosBar>

            )}
          </Section>

        </Container>

        {/* Program edit modal */}
        {this.state.showEditModal &&
          <ModalProgram onClose={this.handleEditModalClose} program={program} />
        }

        {/* Program delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleDeleteModalClose} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer ce programme.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </GenericScene>
    );
  }
}

export default withRouter(ProgramScene);
