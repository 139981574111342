import React from 'react'
import PropTypes from 'prop-types'

import { Label } from 'semantic-ui-react';

import './style.css';

class Index extends React.Component {
  render() {
    return (
      <Label circular className="index">
        {this.props.value}
      </Label>
    );
  }
}

Index.propTypes = {
  value: PropTypes.node.isRequired
};

export default Index;
