import { Typeof } from "utils";

/**
 * Utilities functions.
 *
 * @author Matthieu RENAULT <matthieu.renault@ob-do.com>
 */
class Functions {

  /**
   * Capitalizes the given string.
   * @param {String} string the string to capitalizes
   * @return the capitalized string
   */
  static capitalize(string) {

    if (Typeof.string(string) && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return string;
  }

  static formatCode(code, group, separator) {

    let parts = [];
    let count = code.length / group;

    for(let i = 0; i < group; i++) {
      let j = i * count;
      parts[i] = code.slice(j, j + count);
    }

    return parts.join(separator);
  }

  static truncate(string, length) {
    return string.length > length ? string.substr(0, length - 1) + String.fromCharCode(8230) : string;
  }

  static base64ToImage(data) {
    return `data:image/png;base64,${data}`;
  }
}

export default Functions;
