import React from 'react'

import { Redirect, withRouter } from "react-router-dom";

import { GenericScene } from 'scenes';

import { Button, Header, Modal, Form, Message } from 'semantic-ui-react'

import { Auth } from 'services';

import './style.css';

class LoginScene extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      redirectToReferrer: Auth.isLogged(),
      submit: false,
      error: false
    };
  }

  handleChange(event, change) {
    let state = this.state;
    state[change.id] = change.value;
    this.setState(state);
  }

  handleSubmit() {

    this.setState({
      submit: true,
      error: false
    });

    let { email, password } = this.state;

    Auth.login(email, password)
      .then(() => {
        this.setState({ redirectToReferrer: true });
      })
      .catch((error) => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ submit: false });
      });
  }

  render() {

    let from = this.props.from || "/";

    if (this.state.redirectToReferrer) {
      return <Redirect to={from} />;
    }

    let title = "Accès administrateur";

    return (
      <GenericScene dummy>
        <Modal open size='small' dimmer="blurring" id="login">
          <Header icon='sign-in' content={title} />
          <Modal.Content>
            <Form onSubmit={this.handleSubmit}>

              {/* Email */}
              <Form.Field>
                <label>Adresse e-mail</label>
                <Form.Input id="email" onChange={this.handleChange} placeholder='exemple@domain.com' />
              </Form.Field>

              {/* Password */}
              <Form.Field>
                <label>Mot de passe</label>
                <Form.Input id="password" onChange={this.handleChange} type="password" />
              </Form.Field>

              <div className="actions">
                <Button type='submit' disabled={this.state.submit} loading={this.state.submit}>Se connecter</Button>
              </div>
            </Form>

            {/* Show error */}
            {this.state.error &&
              <Message negative>
                <p>Identifiant et/ou mot de passe incorrect</p>
              </Message>
            }

          </Modal.Content>
        </Modal>
      </GenericScene>
    );
  }
}

export default withRouter(LoginScene);
