import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { Container, Button, Image, Flag, Icon } from 'semantic-ui-react';
import { Section, InfosBar, Aligner, Table, Empty } from 'components';

import { GenericScene } from 'scenes';

import { ModalCategory } from 'modals';

import { EnumLocale } from 'enums';

import { BreadcrumbHelper, Typeof, Functions } from 'utils';

const CATEGORIES_PER_PAGE = 10;

class CategoryListScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      categories: props.categories,
      selectedLocale: EnumLocale.default,
      criteria: '',
      showCreateModal: false
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleLocaleChange = this.handleLocaleChange.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleCreateModalClose = this.handleCreateModalClose.bind(this);
  }

 componentWillMount() {

    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Catégories');
    BreadcrumbHelper.flush();
  }

  handleLocaleChange(event, { value }) {
    this.setState({
      selectedLocale: value,
    });
  }

  handleSearch(criteria) {

    this.setState({
      criteria: criteria
    });
  }

  handleSelect(category) {

    //Navigate to category page
    this.props.history.push(`/categories/${category.id}`);
  }

  toggleCreateModal() {
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  }

  handleCreateModalClose(create) {

    if(Typeof.object(create)) {
      this.state.categories.push(create);
    }

    this.toggleCreateModal();
  }

  render() {

    const { categories } = this.state;

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (category) => category.id,
        fit: true,
      },
      {
        id: 'logo',
        header: 'Logo',
        render: (category) => {

          if(category.logo != null) {
            return <Image src={Functions.base64ToImage(category.logo)} className="logo" />
          } else {
            return <Empty />
          }
        },
        fit: true
      },
      {
        id: 'name',
        header: 'Nom',
        value: (category) => {

          return category.localizations.map((localization) => {
            return localization.name
          });
        },
        render: (category) => {

          return (
            <Aligner direction="vertical" vertical="left" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = category.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>
                    <Flag name={locale.flag.toLowerCase()} />

                    {localization != null && Typeof.string(localization.name) ? (
                      <span>{localization.name}</span>
                    ) : (
                      <Empty />
                    )}
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        id: 'programs',
        header: 'Programme(s)',
        value: (category) => category.programs.length,
        fit: true
      }
    ];

    return (
      <GenericScene>

        <Container>

          <Section title="Liste des catégories disponibles" onSearch={this.handleSearch} actions={[

            // Add category
            <Button icon="add" content="Ajouter" onClick={this.toggleCreateModal} />
          ]}>

            {categories.length > 0 ? (

              <React.Fragment>

                {/* Number of available categories */}
                <InfosBar mode="column">
                  <span>{categories.length > 1 ? `${categories.length} catégories disponibles` : '1 catégorie disponible'}</span>
                </InfosBar>

                {/* Devices list */}
                <Table columns={columns}
                  criteria={this.state.criteria}
                  items={categories}
                  itemsPerPage={CATEGORIES_PER_PAGE}
                  onSelect={this.handleSelect}
                  messageEmpty="Aucune catégorie ne correspond à votre recherche"
                  sort="id"
                  direction="descending"
                />

              </React.Fragment>

            ) : (

              // No category available
              <InfosBar mode="row">
                <span>Aucune catégorie disponible</span>
              </InfosBar>

            )}

          </Section>

        </Container>

        {/* Modal create customer */}
        {this.state.showCreateModal &&
          <ModalCategory onClose={this.handleCreateModalClose} />
        }

      </GenericScene>
    );
  }
}

export default withRouter(CategoryListScene);
