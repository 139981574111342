import { API } from 'services';
import { Typeof } from 'utils';

//--Constants
const TOKEN = "token";

class Auth {

  /**
   * Indicates if the user is logged
   * @return {boolean} true if the user is logged otherwise false
   */
  static isLogged() {
    return Auth.user !== null;
  }

  /**
   * Gets the current logged user
   * @returns {object|null} the current logged user or null
   */
  static getUser() {
    return Auth.user;
  }

  static onAuth(user, token) {
    Auth.user = user;

    if(Typeof.string(token)) {
      localStorage.setItem(TOKEN, token);
    } else {
      localStorage.removeItem(TOKEN);
    }

    API.setToken(token);
  }

  /**
   * Check the latest logged user
   * @return {Promise}
   */
  static check(onComplete) {

    let checkToken = () => {
      let token = localStorage.getItem(TOKEN);

      if (Typeof.string(token)) {

        API.get('users/token', { 'token': token })
          .then((response) => {

            console.log("Token is valid");

            let user = response.data;
            Auth.onAuth(user, token);
          })
          .catch(() => {

            console.log("Token is invalid");

            Auth.onAuth(null, null);
          })
          .finally(onComplete);

      } else {
        onComplete();
      }
    };

    checkToken();
  }

  /**
   * Login with the given credentials
   * @param {string} email
   * @param {string} password
   */
  static login(email, password) {

    return new Promise((resolve, reject) => {

      //Login
      API.post('users/login', { email, password })
        .then((response) => {

          let data = response.data;

          Auth.onAuth(response.data.user, data.token);
          resolve();
        })
        .catch(reject);
    });

  }

  /**
   * Logout the current logged user.
   */
  static logout() {
    return new Promise((resolve, reject) => {

      API.post('/users/logout')
      .then(() => {

        Auth.onAuth(null, null);
        resolve();
      })
      .catch(reject);

    });
  }

  static hasRole(role) {

    return Auth.isLogged() && Auth.user.roles.includes(role);
  }

}

//Set default
Auth.user = null;
Auth.instance = null;

export default Auth;
