import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { Container, Button, Image, Flag, Icon } from 'semantic-ui-react';
import { Section, InfosBar, Aligner, Table, Empty } from 'components';

import { GenericScene } from 'scenes';

import { ModalProgram } from 'modals';

import { EnumLocale } from 'enums';

import { BreadcrumbHelper, Typeof, Functions } from 'utils';

const PROGRAMS_PER_PAGE = 10;

class ProgramListScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      programs: props.programs,
      selectedLocale: EnumLocale.default,
      criteria: '',
      showCreateModal: false
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleLocaleChange = this.handleLocaleChange.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleCreateModalClose = this.handleCreateModalClose.bind(this);
  }

 componentWillMount() {

    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Programmes');
    BreadcrumbHelper.flush();
  }

  handleLocaleChange(event, { value }) {
    this.setState({
      selectedLocale: value,
    });
  }

  handleSearch(criteria) {

    this.setState({
      criteria: criteria
    });
  }

  handleSelect(program) {

    //Navigate to program page
    this.props.history.push(`/programs/${program.id}`);
  }

  toggleCreateModal() {
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  }

  handleCreateModalClose(create) {

    if(Typeof.object(create)) {
      this.state.programs.push(create);
    }

    this.toggleCreateModal();
  }

  render() {

    const { programs } = this.state;

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (program) => program.id,
        fit: true,
      },
      {
        id: 'logo',
        header: 'Logo',
        render: (program) => {

          if(program.logo != null) {
            return <Image src={Functions.base64ToImage(program.logo)} className="logo" />
          } else {
            return <Empty />
          }
        },
        fit: true
      },
      {
        id: 'name',
        header: 'Nom',
        value: (program) => {

          return program.localizations.map((localization) => {
            return localization.name
          });
        },
        render: (program) => {

          return (
            <Aligner direction="vertical" vertical="left" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = program.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>
                    <Flag name={locale.flag.toLowerCase()} />

                    {localization != null && Typeof.string(localization.name) ? (
                      <span>{localization.name}</span>
                    ) : (
                      <Empty />
                    )}
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        id: 'old_name',
        header: 'Ancien nom',
        value: (program) => {

          return program.localizations.map((localization) => {
            return localization.old_name
          });
        },
        render: (program) => {

          return (
            <Aligner direction="vertical" vertical="left" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = program.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>
                    <Flag name={locale.flag.toLowerCase()} />

                    {localization != null && Typeof.string(localization.old_name) ? (
                      <span>{localization.old_name}</span>
                    ) : (
                      <Empty />
                    )}
                  </span>
                )
              })}
            </Aligner>
          )
        }
      }
    ];

    return (
      <GenericScene>

        <Container>

          <Section title="Liste des programmes disponibles" onSearch={this.handleSearch} actions={[

            // Add program
            <Button icon="add" content="Ajouter" onClick={this.toggleCreateModal} />
          ]}>

            {programs.length > 0 ? (

              <React.Fragment>

                {/* Number of available programs */}
                <InfosBar mode="column">
                  <span>{programs.length > 1 ? `${programs.length} programmes disponibles` : '1 programme disponible'}</span>
                </InfosBar>

                {/* Devices list */}
                <Table columns={columns}
                  criteria={this.state.criteria}
                  items={programs}
                  itemsPerPage={PROGRAMS_PER_PAGE}
                  onSelect={this.handleSelect}
                  messageEmpty="Aucun programme ne correspond à votre recherche"
                  sort="id"
                  direction="descending"
                />

              </React.Fragment>

            ) : (

              // No program available
              <InfosBar mode="row">
                <span>Aucun programme disponible</span>
              </InfosBar>

            )}

          </Section>

        </Container>

        {/* Modal create customer */}
        {this.state.showCreateModal &&
          <ModalProgram onClose={this.handleCreateModalClose} />
        }

      </GenericScene>
    );
  }
}

export default withRouter(ProgramListScene);
