import React from 'react'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { CategoryListScene } from 'scenes';

class CategoryListContainer extends AbstractContainer {

  getTasks(params) {

    return [
      {
        key: 'categories',
        exec: (done, failure) => {
          API.get(`categories`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      },
      {
        key: 'programs',
        exec: (done, failure) => {
          API.get(`programs`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <CategoryListScene {...results} />
  }
}

export default CategoryListContainer;
