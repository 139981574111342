import React from 'react'

import classnames from 'classnames';

import './style.css';

class Text extends React.Component {

  render() {
    return (
      <span className={classnames('text', this.props.className, ...this.props.style.split(' '))}>
        {this.props.children}
      </span>
    );
  }
}

export default Text;
