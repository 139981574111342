import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, Checkbox } from 'components';

import { Form, Modal, Header, TransitionablePortal, Icon, Label, Divider, Grid, Flag, Tab, Menu } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof, Functions } from 'utils';

import { EnumLocale } from 'enums';

import './style.css';

class ModalProgam extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.isEdit = Typeof.object(props.program);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: props.onClose
        }
      },

      icon: 'add',
      title: 'Nouveau programme',
      program: {}
    };

    if (this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification programme',
        program: props.program
      };
    }

    this.state = state;
  }

  handleSubmit(data, onSucess, onError) {

    //Remove localization keys
    const filtered = Object.keys(data)
      .filter(key => !key.includes(":"))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    let promises = [];

    let update = () => {

      //Localization
      EnumLocale.values.map((k) => {

        let locale = EnumLocale[k];

        let name = data[`${locale.code}:name`].trim();
        let old_name = data[`${locale.code}:old_name`].trim();

        promises.push(new Promise((resolve, reject) => {

          //Localize
          API.put(`/programs/${this.state.program.id}/localize`, {
            locale: locale.code,
            name: name,
            old_name: old_name
          })
          .then(resolve)
          .catch(reject);

        }));

      });

      //Logo
      let files = this.refs.logo.files;

      if(files.length > 0) {

        promises.push(new Promise((resolve, reject) => {

          let data = new FormData();
          data.append('file', files[0]);

          API.post(`/programs/${this.state.program.id}/logo`, data)
            .then(resolve)
            .catch(reject);
        }));
      }

      //Data
      promises.push(new Promise((resolve, reject) => {
        API.put(`/programs/${this.state.program.id}`, filtered)
          .then(resolve)
          .catch(reject);
      }));

      Promise.all(promises)
        .then(() => {

          API.get(`/programs/${this.state.program.id}`)
            .then((response) => {

              this.props.onClose({
                id: this.state.program.id,
                ...response.data
              });
            });

        })
        .catch((error) => {
          onError(API.violations(error));

          //Delete
          if(!this.isEdit) {
            API.delete(`/programs/${this.state.program.id}`);
          }

        });
    };

    //Create
    if(!this.isEdit) {

      promises.push(
        API.post('/programs', filtered)
          .then((response) => {

            this.setState({
              program: response.data
            }, update);
          })
          .catch((error) => {
            onError(API.violations(error));
          })
      );

    } else {
      update()
    }
  }

  render() {

    const { program } = this.state;

    let panes = EnumLocale.values.map((k) => {

      let locale = EnumLocale[k];
      let localization = Typeof.object(program.localizations) ? program.localizations.find((localization) => {
        return localization.locale == locale.code;
      }) : null;

      return {
        menuItem: (
          <Menu.Item key={locale.code}>
            <Flag name={locale.flag.toLowerCase()} />
            {locale.name}
          </Menu.Item>
        ),
        pane: (
          <Tab.Pane className="localizable">

            {/* Name */}
            <Form.Field>
              <label htmlFor={`inputName_${locale.code}`}>Nom</label>
              <Input id={`inputName_${locale.code}`} name={`${locale.code}:name`} value={localization != null ? localization.name : null} />
            </Form.Field>

            {/* Old name */}
            <Form.Field>
              <label htmlFor={`inputOldName_${locale.code}`}>Ancien nom</label>
              <Input id={`inputOldName_${locale.code}`} name={`${locale.code}:old_name`} value={localization != null ? localization.old_name : null} />
            </Form.Field>

          </Tab.Pane>
        )
      }
    });

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              <Tab menu={{ borderless: true, secondary: true, pointing: true }} panes={panes} renderActiveOnly={false} />

              <Divider />

              {/* Logo */}
              <Form.Field>
                <label htmlFor="inputLogo">Logo</label>
                <input type="file" id="inputMedia" ref="logo" onChange={this.onMediaSet} accept="image/png,image/jpeg" multiple={false} />
              </Form.Field>

              <Form.Field>
                <Checkbox id="inputIsVTG" name="is_vtg" checked={program.is_vtg} label="Grande vitesse" />
              </Form.Field>

              {/* Osciliations */}

              <Divider horizontal>
                Oscillation
              </Divider>

              <Form.Field>
                <label htmlFor="inputNbOsc">Nombre de cycles d'oscillation</label>
                <Input type="number" id="inputNbOsc" name="nb_osc" value={program.nb_osc || 0} />
              </Form.Field>

              <Form.Group widths='equal'>

                <Form.Field>
                  <label htmlFor="inputOscDem">Tempo demarrage</label>
                  <Input type="number" id="inputOscDem" name="osc_dem" value={program.osc_dem || 0} />
                </Form.Field>

                <Form.Field>
                  <label htmlFor="inputOscTra">Tempo travail</label>
                  <Input type="number" id="inputOscTra" name="osc_tra" value={program.osc_tra || 0} />
                </Form.Field>

                <Form.Field>
                  <label htmlFor="inputOscRec">Tempo récupération</label>
                  <Input type="number" id="inputOscRec" name="osc_rec" value={program.osc_rec || 0} />
                </Form.Field>

              </Form.Group>

              {/* Decompression */}

              <Divider horizontal>
                Decompression
              </Divider>

              <Form.Field>
                <label htmlFor="inputNbDec">Nombre de cycles de décompression</label>
                <Input type="number" id="inputNbDec" name="nb_dec" value={program.nb_dec || 0} />
              </Form.Field>

              <Form.Group widths='equal'>

                <Form.Field>
                  <label htmlFor="inputDecPre">Tempo attente première traction</label>
                  <Input type="number" id="inputDecPre" name="dec_pre" value={program.dec_pre || 0} />
                </Form.Field>

                <Form.Field>
                  <label htmlFor="inputDecEti">Tempo attente étirement</label>
                  <Input type="number" id="inputDecEti" name="dec_eti" value={program.dec_eti || 0} />
                </Form.Field>

                <Form.Field>
                  <label htmlFor="inputDecMai">Tempo attente maintien</label>
                  <Input type="number" id="inputDecMai" name="dec_mai" value={program.dec_mai || 0} />
                </Form.Field>

              </Form.Group>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalProgam.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalProgam;
