import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, TextArea } from 'components';

import { Form, Modal, Header, TransitionablePortal  } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof } from 'utils';

const DEFAULT_MAX_ALLOWED_TOKENS = 10;

class ModalRemote extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.isEdit = Typeof.object(props.remote);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: props.onClose
        }
      },

      icon: 'add',
      title: 'Nouvelle télécommande',
      remote: {
        max_allowed_tokens: DEFAULT_MAX_ALLOWED_TOKENS
      },
      method: API.post,
      route: `/devices/${this.props.device.id}/remotes`
    };

    if(this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification télécommande',
        remote: props.remote,
        method: API.put,
        route: `/remotes/${props.remote.id}`
      };
    }

    this.state = state;
  }

  handleSubmit(data, onSucess, onError) {

    this.state.method(`${this.state.route}`, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              {/* MaxAllowedTokens */}
              <Form.Field>
                <label htmlFor="inputMaxAllowedTokens" className="required">Nombre maximal de jetons autorisés</label>
                <Input id="inputMaxAllowedTokens" name="max_allowed_tokens" type="number" value={this.state.remote.max_allowed_tokens} />
              </Form.Field>

              {/* Extra */}
              <Form.Field>
                <label htmlFor="inputExtra">Extra</label>
                <TextArea id="inputExtra" name="extra" value={this.state.remote.extra} />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalRemote.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalRemote;
