import React from 'react'
import PropTypes from 'prop-types'

import { Form, Item, Flag } from 'semantic-ui-react'
import { FormWrapper, Selection, Aligner, Empty } from 'components';

import { ModalOptions } from 'modals';

import { Typeof, Functions } from 'utils';

import { EnumLocale } from 'enums';

class ModalAssociateEntity extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      selected: undefined
    };
  }

  handleChange(id) {
    this.setState({
      selected: id
    });
  }

  handleClose(result, setProcessing) {
    this.props.onClose(result, setProcessing, result === ModalOptions.OPTION_YES ? this.state.selected : undefined);
  }

  render() {

    let availableEntities = this.props.entities.filter((entity) => {
      return !this.props.associated.includes(entity.id);
    }).map((entity) => {
      return {
        key: entity.id,
        text: (
          <Item.Group>
            <Item>

              {/* Logo */}
              <Item.Image src={Functions.base64ToImage(entity.logo)} className="logo" />

              {/* Name */}
              <Item.Content>

                <Aligner direction="vertical" vertical="left" className="name">
                  {EnumLocale.values.map((k) => {

                    let locale = EnumLocale[k];
                    let localization = entity.localizations.find((localization) => {
                      return localization.locale == locale.code;
                    });

                    return (
                      <span key={locale.code}>
                        <Flag name={locale.flag.toLowerCase()} />

                        {localization != null && Typeof.string(localization.name) ? (
                          <span>{localization.name}</span>
                        ) : (
                          <Empty />
                        )}
                      </span>
                    )
                  })}
                </Aligner>
              </Item.Content>
            </Item>
          </Item.Group>
        ),
        value: entity.id
      }
    });

    let options = [
      {
        name: 'Valider',
        icon: 'checkmark',
        color: 'red',
        value: ModalOptions.OPTION_YES,
        disabled: Typeof.undefined(this.state.selected)
      },
      {
        name: 'Annuler',
        icon: 'remove',
        value: ModalOptions.OPTION_CANCEL
      }
    ];

    const { config } = this.props;

    return (

      <ModalOptions icon="trash alternate" title={config.title} options={options} onClose={this.handleClose} >

        <FormWrapper>

          {/* Entity */}
          <Form.Field>
            <label forHTML="input">{config.field}</label>
            <Selection
              id="input"
              name="entity"
              options={availableEntities}
              noResultsMessage={config.empty}
              onChange={this.handleChange}
            />
          </Form.Field>

        </FormWrapper>

      </ModalOptions>
    );
  }
}

ModalAssociateEntity.defaultProps = {
  associated: []
};

ModalAssociateEntity.propTypes = {
  associated: PropTypes.arrayOf(PropTypes.number),
  onClose: PropTypes.func.isRequired,
  config: PropTypes.shape({
    title: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    empty: PropTypes.string.isRequired
  })
};

export default ModalAssociateEntity;
