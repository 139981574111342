import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment';

import { withRouter } from 'react-router-dom';

import { GenericScene } from 'scenes';

import { ModalOptions, ModalDevice, ModalRemote } from 'modals';

import { Container, Button, Label, Icon } from 'semantic-ui-react';
import { Section, Properties, InfosBar, Table, TextGroup, Empty, Text } from 'components';

import { API } from 'services';
import { Typeof, BreadcrumbHelper } from 'utils';

import './style.css';

const REMOTES_PER_PAGE = 10;

class DeviceScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      device: props.device,
      remotes: props.remotes,
      showEditModal: false,
      showDeleteModal: false,
      remoteSearchCriteria: '',
      showRemoteCreateModal: false
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);

    this.handleRemoteSearch = this.handleRemoteSearch.bind(this);

    this.handleRemoteSelect = this.handleRemoteSelect.bind(this);

    this.toggleRemoteCreateModal = this.toggleRemoteCreateModal.bind(this);
    this.handleCloseRemoteCreateModal = this.handleCloseRemoteCreateModal.bind(this);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Appareils installés', '/devices');
    BreadcrumbHelper.push(this.state.device.name);
    BreadcrumbHelper.flush();
  }

  toggleEditModal() {
    this.setState({
      showEditModal: !this.state.showEditModal
    });
  }

  handleCloseEditModal(edit) {

    if(Typeof.object(edit)) {

      //Update
      this.setState({
        device: {
          ...this.state.device,
          name: edit.name,
          mac_address: edit.mac_address,
          address: edit.address,
          zip_code: edit.zip_code,
          city: edit.city
        }
      }, this.updateBreadcrumb);
    }

    this.toggleEditModal();
  }

  toggleDeleteModal() {

    //Show modal
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleCloseDeleteModal(result, setProcessing) {

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      API.delete(`/devices/${this.state.device.id}`)
        .then(() => {

          //Go back to devices list
          this.props.history.push('/devices');
        });

    } else {

      //Hide modal
      this.toggleDeleteModal();
    }

  }

  handleRemoteSearch(criteria) {

    this.setState({
      remoteSearchCriteria: criteria
    });

  }

  handleRemoteSelect(remote) {

    //Navigate to device page
    this.props.history.push(`/devices/${this.state.device.id}/remotes/${remote.id}`);
  }

  toggleRemoteCreateModal() {

    //Show modal
    this.setState({
      showRemoteCreateModal: !this.state.showRemoteCreateModal
    });

  }

  handleCloseRemoteCreateModal(result) {

    if(Typeof.object(result)) {

      this.state.remotes.push(result);
      this.forceUpdate();
    }

    this.toggleRemoteCreateModal();
  }

  render() {

    const { device, remotes } = this.state;

    let deviceProperties = [
      {
        name: 'Nom',
        value: device.name
      },
      {
        name: 'Adresse MAC',
        value: <Text style="mono">{device.mac_address}</Text>
      },
      {
        name: 'Adresse',
        value: device.address
      },
      {
        name: 'Code postal',
        value: device.zip_code

      },
      {
        name: 'Ville',
        value: device.city

      }
    ];

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (remote) => remote.id,
        render: (remote) => {
          return (
            <Text style="mono">
              {remote.id}
            </Text>
          )
        },
        fit: true
      },
      {
        id: 'version',
        header: 'Version',
        value: (remote) => [remote.version, remote.ios_version],
        render: (remote, versions) => {

          return (
            <TextGroup mode="rows">

              {/* App */}
              <Text style="mono">
                <Icon name="tablet" />

                {Typeof.string(versions[0]) ? (
                  <Text style="mono">{versions[0]}</Text>
                ) : (
                  <Empty />
                )}

              </Text>

              {/* iOS */}
              <Text style="mono">
                <Icon name="apple" />

                {Typeof.string(versions[1]) ? (
                  <Text style="mono">{versions[1]}</Text>
                ) : (
                  <Empty />
                )}

              </Text>

            </TextGroup>
          )
        }
      },
      {
        id: 'last_synchronization',
        header: 'Synchronisation',
        value: (remote) => Typeof.string(remote.last_synchronization) ? moment(remote.last_synchronization).format('DD/MM/YYYY HH:mm:ss') : undefined,
        render: (remote) => {

          if(Typeof.undefined(remote.last_synchronization)) {
            return <Empty />
          }

          return moment(remote.last_synchronization).format('[Le] DD/MM/YYYY [à] HH:mm:ss')
        }
      },
      {
        id: 'extra',
        header: 'Extra',
        value: (remote) => Typeof.string(remote.extra) && remote.extra.trim().length > 0 ? remote.extra.trim() : undefined,
        render: (remote, value) => {

          if(Typeof.undefined(value)) {
            return <Empty />
          }

          return (
            <div className="remote extra">{value}</div>
          );
        }
      },
      {
        id: 'remaining_tokens',
        header: 'Jetons restants',
        value: (remote) => [remote.remaining_tokens, remote.max_allowed_tokens],
        render: (remote, values) => {
          return (
            <span>
              <Label circular>{values[0]}</Label> sur <Label circular>{values[1]}</Label>
            </span>
          )
        },
        fit: true
      }
    ];

    return (
      <GenericScene navigation>

        <Container>

          <Section title="Informations" actions={[
            <Button icon="edit" circular onClick={this.toggleEditModal} key={0} />,
            <Button icon="trash" circular color="red" onClick={this.toggleDeleteModal} key={1} />
          ]}>

            <Properties properties={deviceProperties} />
          </Section>

        </Container>

        <Container>

          <Section title="Télécommandes" onSearch={this.handleRemoteSearch} actions={[
            <Button icon="add" content="Ajouter" onClick={() => this.toggleRemoteCreateModal()} />
          ]}>

            {remotes.length > 0 ? (

              <React.Fragment>

                {/* Number of available remotes */}
                <InfosBar mode="column">
                  <span>{remotes.length > 1 ? `${remotes.length} télécommandes disponibles` : '1 télécommande disponible'}</span>
                </InfosBar>

                {/* Devices list */}
                <Table columns={columns}
                  criteria={this.state.remoteSearchCriteria}
                  items={remotes}
                  itemsPerPage={REMOTES_PER_PAGE}
                  onSelect={this.handleRemoteSelect}
                  messageEmpty="Aucune télécommande ne correspond à votre recherche"
                  sort="id"
                  direction="ascending"
                />

              </React.Fragment>

            ) : (

              // No device available
              <InfosBar mode="row">
                <span>Aucun télécommande disponible</span>
              </InfosBar>

            )}
          </Section>

        </Container>

        {/* Device edit modal */}
        {this.state.showEditModal &&
          <ModalDevice onClose={this.handleCloseEditModal} device={device} />
        }

        {/* Device delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleCloseDeleteModal} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer cet appareil.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

        {/* Remote create modal */}
        {this.state.showRemoteCreateModal &&
          <ModalRemote onClose={this.handleCloseRemoteCreateModal} device={device} />
        }

      </GenericScene>
    );
  }
}

export default withRouter(DeviceScene);
