import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import { NavigationBar } from 'components';
import { Sidebar, Image, List, Popup, Menu, Icon } from 'semantic-ui-react'

import { Auth } from 'services';
import { Env } from 'env';

import { Typeof } from 'utils';

import classnames from 'classnames';

import logo from 'assets/logo.png';

import './scene.css';

class GenericScene extends React.Component {

  constructor(props) {
    super(props);

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleSidebarHide = this.handleSidebarHide.bind(this);

    this.state = {
      showMenu: true
    };
  }

  toggleSidebar() {

    this.setState({
      showMenu: !this.state.showMenu
    });
  }

  handleSidebarHide() {

    this.setState({
      showMenu: false
    });
  }

  render() {

    let user = Auth.getUser();

    let routes = [];

    if(Auth.isLogged()) {

      routes.push({
        separator: true,
        name: 'Configuration'
      });

      // Programs
      routes.push({
        path: '/programs',
        name: 'Programmes'
      });

      // Categories
      routes.push({
        path: '/categories',
        name: 'Catégories'
      });

      routes.push({
        separator: true,
        name: 'Général'
      });

      // Devices
      routes.push({
        path: '/devices',
        name: 'Appareils installés'
      });

    }

    return (

      <React.Fragment>

        <Sidebar.Pushable className={classnames("sidebar-container", { 'navigation': this.props.navigation })}>

          <Sidebar animation='overlay' visible={this.state.showMenu && !this.props.dummy && this.props.navigation}>

            {/* Footer */}
            <div id="header">
              <Image src={logo} />
              DPA - v

              {/* Build informations */}
              <Popup
                trigger={<span className="version">{Env.BUILD_VERSION}</span>} on="hover" position='bottom center'
                content={
                  <List>
                    <List.Item>
                      <List.Icon name='calendar outline' />
                      <List.Content>{Env.BUILD_DATE}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name='code branch' />
                      <List.Content>{Env.BUILD_HASH}</List.Content>
                    </List.Item>
                  </List>
                }
             />
            </div>

            {/* Items */}
            <div id="items">

              {/* Navigarion */}
              <Menu fluid vertical pointing secondary>
                {routes.map((route, index) => {

                  if(route.separator) {
                    return (
                      <div className="separator">
                        {route.name}
                      </div>
                    )
                  }

                  let granted = true;

                  if(user != null && Typeof.array(route.roles)) {

                    granted = user.roles.some((role) => {
                      return route.roles.includes(role);
                    });
                  }

                  if(granted) {

                    let isCurrent = this.props.match.url.includes(route.path);

                    return (
                      <Link key={index} to={route.path}>
                        <Menu.Item active={isCurrent}>

                          {/* Icon */}
                          {Typeof.string(route.icon) &&
                            <Icon name={route.icon} />
                          }

                          {/* Name */}
                          {route.name}
                        </Menu.Item>
                      </Link>
                    );
                  }

                  return null;
                })}
              </Menu>
            </div>

          </Sidebar>

          <Sidebar.Pusher>

            <div id="content" className={classnames({ 'dummy': this.props.dummy })}>
              {this.props.children}
            </div>

          </Sidebar.Pusher>

        </Sidebar.Pushable>

        {/* Navigation bar */}
        {this.props.navigation &&
          <NavigationBar onMenuToggle={this.toggleSidebar} dummy={this.props.dummy} />
        }

      </React.Fragment>
    );
  }
}

GenericScene.defaultProps = {
  dummy: false,
  navigation: true
};

GenericScene.propTypes = {
  dummy: PropTypes.bool.isRequired,
  navigation: PropTypes.bool.isRequired
};

export default withRouter(GenericScene);
