import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, Text } from 'components';

import { Form, Modal, Header, TransitionablePortal, Icon, Label, Divider } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof, Functions } from 'utils';

class ModalDevice extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.isEdit = Typeof.object(props.device);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: props.onClose
        }
      },

      icon: 'add',
      title: 'Nouvel appareil',
      device: {},
      method: API.post,
      route: '/devices'
    };

    if(this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification appareil',
        device: props.device,
        method: API.put,
        route: `/devices/${props.device.id}`
      };
    }

    this.state = state;
  }

  handleSubmit(data, onSucess, onError) {

    this.state.method(`${this.state.route}`, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              <Form.Group widths='equal'>

                {/* Name */}
                <Form.Field>
                  <label htmlFor="inputName" className="required">Nom</label>
                  <Input id="inputName" name="name" value={this.state.device.name} />
                </Form.Field>

                {/* MAC address */}
                <Form.Field>
                  <label htmlFor="inputMacAddress" className="required">Adresse MAC</label>
                  <Text style="mono uppercase">
                    <Input id="inputMacAddress" name="mac_address" value={this.state.device.mac_address} placeholder="XX:XX:XX:XX:XX:XX" />
                  </Text>
                </Form.Field>

              </Form.Group>

              <Divider horizontal>Localisation</Divider>

              {/* Address */}
              <Form.Field>
                <label htmlFor="inputAddress" className="required">Adresse</label>
                <Input id="inputAddress" name="address" value={this.state.device.address} />
              </Form.Field>

              <Form.Group widths='equal'>

                {/* Zip code */}
                <Form.Field>
                  <label htmlFor="inputZipCode" className="required">Code postal</label>
                  <Input id="inputZipCode" name="zip_code" value={this.state.device.zip_code} />
                </Form.Field>

                {/* City */}
                <Form.Field>
                  <label htmlFor="inputCity" className="required">Ville</label>
                  <Input id="inputCity" name="city" value={this.state.device.city} />
                </Form.Field>

              </Form.Group>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalDevice.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalDevice;
