import { Modal, Button, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import React from 'react'

class UnauthorizedScene extends React.Component {

  constructor(props) {
    super(props);

    this.onBackHome = this.onBackHome.bind(this);
  }

  onBackHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      <Modal open size='small' dimmer="blurring">
        <Header icon='lock' content='Resource sécurisée' style={{backgroundColor: 'black'}} />
        <Modal.Content>
          <p>Vous n'avez pas les autorisations nécessaires pour accéder à la page demandée</p>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="home" content="Retour à l'accueil" onClick={this.onBackHome} />
        </Modal.Actions>
      </Modal>
      )
    }
  }

  export default withRouter(UnauthorizedScene);
