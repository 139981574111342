import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Popup } from 'semantic-ui-react'
import { Breadcrumb } from 'components';
import { Link } from 'react-router-dom';

import { Auth } from 'services';

import { Typeof } from 'utils';

import moment from 'moment';

import './style.css';

class NavigationBar extends React.Component {

  renderAccount() {
    let user = Auth.getUser();

    return (
      <Popup flowing hoverable position="bottom right" trigger={
        <div id="account">
          <div className="infos">
            <div>

              {/* Firstname */}
              <span className="firstname">{user.firstname}</span>

              {/* Lastname */}
              {Typeof.string(user.lastname) &&
                <span className="lastname">{user.lastname}</span>
              }

            </div>
            <span className="email">{user.email}</span>
          </div>
          <Icon name="user" inverted size="large" />
        </div>
      }>
        <div id="accountPopup">
          <span className="logged-at">{moment(user.logged_at).format('[Connecté le] DD/MM/YYYY [à] HH:mm:ss')}</span>
          <span>
            <Icon name="logout" />
            <Link to="/logout">Déconnexion</Link>
          </span>
        </div>
      </Popup>
    );
  }

  render() {

    return (
      <div id="navigationBar">

        <div>
          {/* Menu */}
          <Icon name="bars" inverted size="large" className="menu-toggle" onClick={!this.props.dummy ? this.props.onMenuToggle : null} />

          {/* Breadcrumb */}
          <Breadcrumb />
        </div>

        {/* Account */}
        {!this.props.dummy && Auth.isLogged() &&
          this.renderAccount()
        }

      </div>
    );
  }
}

NavigationBar.defaultProps = {
  dummy: false
};

NavigationBar.propTypes = {
  dummy: PropTypes.func.isRequired,
  onMenuToggle: PropTypes.func.isRequired
};

export default NavigationBar;
