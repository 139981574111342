import React from 'react'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { CategoryScene } from 'scenes';

class CategoryContainer extends AbstractContainer {

  getTasks(params) {

    const id = params.category;

    return [
      {
        key: 'category',
        exec: (done, failure) => {
          API.get(`categories/${id}`)
            .then((response) => {
              let device = {
                ...response.data,
                id: id
              };

              done(device);
            })
            .catch(failure);
        }
      },
      {
        key: 'programs',
        exec: (done, failure) => {
          API.get('programs')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <CategoryScene {...results} />
  }
}

export default CategoryContainer;
