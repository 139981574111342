import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { GenericScene } from 'scenes';

import { Container, Button, Label, Flag, Image } from 'semantic-ui-react';
import { Section, Properties, Activation, Empty, Text, InfosBar, Table, Aligner } from 'components';

import { Link } from 'react-router-dom';

import { ModalOptions, ModalRemote, ModalAssociateEntity } from 'modals';

import { API } from 'services';
import { Typeof, BreadcrumbHelper, Functions } from 'utils';

import { EnumLocale } from 'enums';

import moment from 'moment';

import './style.css';

const CATEGORIES_PER_PAGE = 10;

class RemoteScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      remote: props.remote,
      showEditModal: false,
      showDeleteModal: false,
      showEditModal: false,
      showDeleteModal: false,
      showRevokeModal: false,
      categorySearchCriteria: '',
      categoryToDissociate: null,
      showCategoryAssociateModal: false,
      showCategoryDissociateModal: false,
    };

    this.toggleRevokeModal = this.toggleRevokeModal.bind(this);
    this.handleCloseRevokeModal = this.handleCloseRevokeModal.bind(this);

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);

    this.handleCategorySearch = this.handleCategorySearch.bind(this);

    this.handleCategorySelect = this.handleCategorySelect.bind(this);

    this.toggleCategoryAssociateModal = this.toggleCategoryAssociateModal.bind(this);
    this.handleCategoryAssociateModalClose = this.handleCategoryAssociateModalClose.bind(this);

    this.toggleCategoryDissociateModal = this.toggleCategoryDissociateModal.bind(this);
    this.handleCategoryDissociateModalClose = this.handleCategoryDissociateModalClose.bind(this);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Appareils installés', '/devices');
    BreadcrumbHelper.push(this.props.device.name, `/devices/${this.state.remote.device}`);
    BreadcrumbHelper.push(`Télécommande #${this.state.remote.id}`);
    BreadcrumbHelper.flush();
  }

  toggleRevokeModal() {

    //Show modal
    this.setState({
      showRevokeModal: !this.state.showRevokeModal
    });
  }

  handleCloseRevokeModal(result, setProcessing) {

    let promises = [];

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      promises.push(
        API.delete(`/remotes/${this.state.remote.id}/revoke`)
          .then((response) => {

            //Update remote activation code
            this.setState({
              remote: {
                ...this.state.remote,
                activation_code: response.data.code,
                activated: false
              }
            });

          })
      );

    }

    Promise.all(promises).then(() => this.toggleRevokeModal());
  }

  toggleEditModal() {

    //Show modal
    this.setState({
      showEditModal: !this.state.showEditModal
    });
  }

  handleCloseEditModal(result) {

    if(Typeof.object(result)) {

      //Update locally
      this.setState({
        remote: {
          ...this.state.remote,
          extra: result.extra,
          max_allowed_tokens: result.max_allowed_tokens
        }
      });

      this.forceUpdate();
    }

    this.toggleEditModal();
  }

  toggleDeleteModal() {

    //Show modal
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleCloseDeleteModal(result, setProcessing) {

    let promises = [];

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      promises.push(
        API.delete(`/remotes/${this.state.remote.id}`)
          .then((response) => {

            //Go back to devices list
            this.props.history.push(`/devices/${this.props.device.id}`);
          })
      );
    }

    Promise.all(promises).then(() => this.toggleDeleteModal());
  }

  handleCategorySearch(criteria) {

    this.setState({
      categorySearchCriteria: criteria
    });

  }

  handleCategorySelect(category) {
    this.props.history.push(`/categories/${category.id}`);
  }

  toggleCategoryAssociateModal() {

    this.setState({
      showCategoryAssociateModal: !this.state.showCategoryAssociateModal
    });
  }

  handleCategoryAssociateModalClose(result, setProcessing, category) {

    let promises = [];

    if (result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      promises.push(
        API.put(`/remotes/${this.state.remote.id}/categories/${category}`)
          .then(() => {

            this.state.remote.categories.push(category);
          })
      );

    }

    Promise.all(promises).then(() => this.toggleCategoryAssociateModal());
  }

  toggleCategoryDissociateModal(category = null, event = null) {

    if (event) {
      event.stopPropagation();
    }

    //Show modal
    this.setState({
      categoryToDissociate: category,
      showCategoryDissociateModal: !this.state.showCategoryDissociateModal
    });
  }

  handleCategoryDissociateModalClose(result, setProcessing) {

    let promises = [];

    if (result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      promises.push(

        API.delete(`/remotes/${this.state.remote.id}/categories/${this.state.categoryToDissociate}`)
          .then(() => {

            //Dissociate categories
            this.state.remote.categories = this.state.remote.categories.filter((category) => {
              return category !== this.state.categoryToDissociate
            });
          })

      );
    }

    Promise.all(promises).then(() => this.toggleCategoryDissociateModal(null));
  }

  render() {

    const { remote } = this.state;
    const { device, categories } = this.props;

    let remoteProperties = [
      {
        name: "Jetons",
        value: () => {
          return (
            <span>
              <Label circular>{remote.remaining_tokens}</Label> sur <Label circular>{remote.max_allowed_tokens}</Label>
            </span>
          )
        }
      },
      {
        name: "Extra",
        value: () => {

          if(Typeof.undefined(remote.extra)) {
            return <Empty />
          }

          return (
            <div className="remote extra">{remote.extra}</div>
          );
        }
      },
      {
        name: () => {
          return (
            <React.Fragment>
              Activation

              {/* Revoke link */}
              {remote.activated &&
                <Link className="activation-revoke" onClick={this.toggleRevokeModal}>(Révoquer)</Link>
              }
            </React.Fragment>
          )
        },
        value: () => {
          return (
            <Activation code={remote.activation_code.toUpperCase()} activated={remote.activated} />
          )
        }
      },
      {
        name: "Version iOS",
        value: Typeof.string(remote.ios_version) ? <Text style="mono">{remote.ios_version}</Text> : <Empty />
      },
      {
        name: "Version de l'application",
        value: Typeof.string(remote.version) ? <Text style="mono">{remote.version}</Text> : <Empty />
      },
      {
        name: "Date de dernière synchronisation",
        value: Typeof.string(remote.last_synchronization) ? moment(remote.last_synchronization).format('[Le] DD/MM/YYYY [à] HH:mm:ss') : <Empty />
      }
    ];

    const availableCategories = categories.filter((category) => {
      return remote.categories.includes(category.id);
    });

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (category) => category.id,
        fit: true
      },
      {
        id: 'logo',
        header: 'Logo',
        render: (category) => {

          if (category.logo != null) {
            return <Image src={Functions.base64ToImage(category.logo)} className="logo" />
          } else {
            return <Empty />
          }
        },
        fit: true
      },
      {
        id: 'name',
        header: 'Nom',
        value: (category) => {

          return category.localizations.map((localization) => {
            return localization.name
          });
        },
        render: (category) => {

          return (
            <Aligner direction="vertical" vertical="left" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = category.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>
                    <Flag name={locale.flag.toLowerCase()} />

                    {localization != null && Typeof.string(localization.name) ? (
                      <span>{localization.name}</span>
                    ) : (
                        <Empty />
                      )}
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        id: 'action',
        header: 'Actions',
        render: (category) => {
          return (
            <Button icon="unlink" size="tiny" color="red" circular onClick={(event) => this.toggleCategoryDissociateModal(category.id, event)} />
          );
        },
        fit: true
      }
    ];

    return (
      <GenericScene navigation>

        <Container>

          <Section title="Informations" actions={[
            <Button icon="edit" circular onClick={this.toggleEditModal} key={0} />,
            <Button icon="trash" circular color="red" onClick={this.toggleDeleteModal} key={1} />
          ]}>

            <Properties properties={remoteProperties} />
          </Section>

        </Container>

         <Container>

          <Section title="Catégories associés" onSearch={this.handleCategorySearch} criteria={this.state.categorySearchCriteria} actions={[
            <Button content="Associer" onClick={this.toggleCategoryAssociateModal} key={0} />
          ]}>

            {availableCategories.length > 0 ? (

              <React.Fragment>

                <InfosBar mode="column">

                  {/* Number of associated categories */}
                  <span>{availableCategories.length > 1 ? `${availableCategories.length} catégories associées` : '1 catégorie associée'}</span>

                </InfosBar>

                {/* Rooms list */}
                <Table columns={columns}
                  criteria={this.state.categorySearchCriteria}
                  items={availableCategories}
                  itemsPerPage={CATEGORIES_PER_PAGE}
                  onSelect={this.handleCategorySelect}
                  messageEmpty="Aucune catégorie ne correspond à votre recherche"
                  sort="id"
                  direction="ascending"
                />

              </React.Fragment>

            ) : (

              // No categorie associated
              <InfosBar mode="row">
                <span>Aucun catéogorie associée</span>

              </InfosBar>

            )}
          </Section>

        </Container>


        {/* Remote edit modal */}
        {this.state.showEditModal &&
          <ModalRemote onClose={this.handleCloseEditModal} device={device} remote={this.state.remote} />
        }

        {/* Remote delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleCloseDeleteModal} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer cette télécommande.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

        {/* Remote revoke warning modal */}
        {this.state.showRevokeModal &&
          <ModalOptions onClose={this.handleCloseRevokeModal} icon="trash alternate" title="Révoquer" message={<span>Vous êtes sur le point de révoquer l'activation de cette télécommande.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

        {/* Category association modal */}
        {this.state.showCategoryAssociateModal &&
          <ModalAssociateEntity onClose={this.handleCategoryAssociateModalClose} entities={categories} associated={remote.categories} config={{
            title: "Associer une catégorie",
            field: "Catégorie",
            empty: "Aucune catégorie n'est disponible"
          }} />
        }

        {/* Category dissociation modal */}
        {this.state.showCategoryDissociateModal &&
          <ModalOptions onClose={this.handleCategoryDissociateModalClose} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de dissocier la catégorie de cette télécommande.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </GenericScene>
    )
  }
}

export default withRouter(RemoteScene);
