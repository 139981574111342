import dev from './dev.js';
import prod from './prod.js';

let target = process.env.NODE_ENV === 'production' ? prod : dev;

class Env {
  // Build
  static BUILD_VERSION = process.env.REACT_APP_VERSION;
  static BUILD_HASH    = process.env.REACT_APP_BUILD_HASH;
  static BUILD_DATE    = process.env.REACT_APP_BUILD_DATE;

  // API
  static HOST = target.HOST;
  static PORT = target.PORT;
}

export { Env }
