import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox as SemanticCheckbox} from 'semantic-ui-react';

import { Typeof } from 'utils';

class Checkbox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { checked }) {

    this.setState({
      checked: checked
    });

    if(Typeof.func(this.props.onChange)) {
      this.props.onChange(event);
    }
  }

  render() {
    let { checked, ...rest } = this.props;

    return (
      <SemanticCheckbox {...rest} checked={this.state.checked} onChange={this.handleChange} />
    );
  }
}

export default Checkbox;
