import React from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom';

import { GenericScene } from 'scenes';

import { Modal, Header, Icon } from 'semantic-ui-react';

import { Auth } from 'services';

class InstanceLogoutScene extends React.Component {

  constructor() {
    super();

    this.state = {
      logged: Auth.isLogged()
    };
  }

  componentDidMount() {

    if(this.state.logged) {

      Auth.logout().then(() => {
        this.setState({ logged: false });
      })
    }

  }

  render() {

    //Not logged, redirect to home
    if(!this.state.logged) {
      return (
        <Redirect to="/" />
      );
    }

    return (
      <GenericScene dummy>
        <Modal open size='tiny' dimmer="blurring">
          <Header icon='sign-out' content='Déconnexion' />
          <Modal.Content>
            <Icon loading name='spinner' />
            Déconnexion en cours...
        </Modal.Content>
        </Modal>
      </GenericScene>
    );
  }
}

export default InstanceLogoutScene;
