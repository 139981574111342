import React from 'react'
import PropTypes from 'prop-types'

import { Icon, Button, Popup } from 'semantic-ui-react';
import { Text } from 'components';

import { Functions } from 'utils';

import './style.css';

class Activation extends React.Component {

  constructor(props) {
    super(props);

    this.handleCopy = this.handleCopy.bind(this);
  }

  handleCopy(event) {
    event.stopPropagation();

    let body = document.getElementsByTagName('body')[0];
    let input = document.createElement('input');
    body.appendChild(input);
    input.setAttribute('value', Functions.formatCode(this.props.code, 3, '-'));
    input.select();
    document.execCommand('copy');
    body.removeChild(input);
  }

  render() {

    const { activated } = this.props;

    let color = activated ? 'red' : 'yellow';
    let icon = activated ? 'lock' : 'lock open';
    let status = activated ? 'Activé' : 'Désactivé';

    let state = (
      <Icon name={icon} color={color} />
    );

    return (
      <div className="activation">

        {/* State */}
        <Popup
          trigger={state}
          content={status}
          position='bottom center'
          inverted
        />

        {/* Code */}
        <Text className="code" style="mono">{Functions.formatCode(this.props.code, 3, '-')}</Text>

        {/* Copy */}
        {this.props.copy &&
          <Button className="copy" icon="copy" compact size="mini" onClick={this.handleCopy} />
        }

      </div>
    );
  }
}

Activation.defaultProps = {
  copy: true
};

Activation.propTypes = {
  code: PropTypes.string.isRequired,
  activated: PropTypes.bool.isRequired,
  copy: PropTypes.bool.isRequired
};

export default Activation;
