import axios from 'axios';
import { Typeof, Functions } from 'utils';

import { Env } from 'env';

//--Constants
const API_URL = `${window.location.protocol}//${Env.HOST}:${Env.PORT}/backend/api/v1`;

const AXIOS = axios.create({
  baseURL: API_URL
});

class API {

  static setToken(token) {

    //Set JWT token as default
    API.token = token;
    AXIOS.defaults.headers['Authorization'] = `Bearer ${API.token}`;
  }

  static queryParams(params = null) {

    if (Typeof.object(params)) {
      return '?' + Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    }

    return '';
  }

  static get(route, params = null) {

    return new Promise((resolve, reject) => {

      AXIOS.get(`${route}${API.queryParams(params)}`)
        .then(resolve)
        .catch((error) => {

          console.log(error);
          reject(error);
        });

    });

  }

  static post(route, params) {

    return new Promise((resolve, reject) => {

      AXIOS.post(route, params)
        .then(resolve)
        .catch((error) => {

          console.log(error);
          reject(error);
        });

    });

  }

  static put(route, params) {

    return new Promise((resolve, reject) => {

      AXIOS.put(route, params)
        .then(resolve)
        .catch((error) => {

          console.log(error);
          reject(error);
        });

    });

  }

  static delete(route) {

    return new Promise((resolve, reject) => {

      AXIOS.delete(route)
        .then(resolve)
        .catch((error) => {

          console.log(error);
          reject(error);
        });

    });

  }

  static open(route) {
    AXIOS.get(route, {
      responseType: 'arraybuffer'
    }).then((response) => {

      var file = new Blob([response.data], { type: response.headers['content-type'] });
      let url = URL.createObjectURL(file);
      window.open(url, '_blank');
    })
  }

  static url(url, token = false) {

    let _url = `${API_URL}/${url}`;

    if (token) {
      _url += API.queryParams({ 'Bearer': API.token })
    }

    return _url;
  }

  /**
  * Parses violations from the given API error
  * @param {*} error
  */
  static violations(error) {

    let violations = {};

    if (Typeof.object(error.response) && Typeof.object(error.response.data) && Typeof.string(error.response.data.message)) {

      try {
        let causes = JSON.parse(error.response.data.message);

        causes.forEach((violation) => {

          if (!violations.hasOwnProperty(violation.field)) {
            violations[violation.field] = [];
          }

          violations[violation.field].push(Functions.capitalize(violation.cause));

        });
      }
      catch (exception) {
        console.error('Unable to parse violations: ' + exception.message);
      }
    }

    return violations;
  }
}

export default API;
