import React from 'react'
import PropTypes from 'prop-types'

import { Input as SemanticInput } from 'semantic-ui-react';

import { Typeof } from 'utils';

class Input extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { value }) {

    this.setState({
      value: value
    });

    if(Typeof.func(this.props.onChange)) {
      this.props.onChange(event);
    }
  }

  render() {
    let { value, ...rest} = this.props;

    return (
      <SemanticInput {...rest} value={this.state.value} onChange={this.handleChange} />
    );
  }
}

export default Input;
