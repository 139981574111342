import React from 'react';

import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { PrivateRoute, PublicRoute } from 'routes';

import { Splash } from 'components';

import {
  NotFoundScene,
  LogoutScene
} from 'scenes';

import {
  CategoryListContainer,
  CategoryContainer,
  ProgramListContainer,
  ProgramContainer,
  DeviceListContainer,
  DeviceContainer,
  RemoteContainer
} from 'containers';

import { Auth } from 'services';

import 'semantic-ui-css/semantic.min.css'
import 'styles/main.css';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isChecked: false
    };
  }

  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  componentWillMount() {

    //Check for user token
    Auth.check(() => {
      this.setState({
        isChecked: true
      });
    });
  }

  render() {

    if(!this.state.isChecked) {
      return <Splash visible message="Vérification du jeton d'accès" fade/>
    }

    return (
      <Router>

        <Switch>

          {/* Home */}
          <PrivateRoute path="/logout" exact component={LogoutScene} />

          {/* Categories */}
          <PrivateRoute path="/categories" exact component={CategoryListContainer} />
          <PrivateRoute path="/categories/:category([0-9]+)" exact component={CategoryContainer} />

          {/* Programs */}
          <PrivateRoute path="/programs" exact component={ProgramListContainer} />
          <PrivateRoute path="/programs/:program([0-9]+)" exact component={ProgramContainer} />

          {/* Devices */}
          <PrivateRoute path="/devices" exact component={DeviceListContainer} />
          <PrivateRoute path="/devices/:device([0-9]+)" exact component={DeviceContainer} />

          {/* Remote */}
          <PrivateRoute path="/devices/:device([0-9]+)/remotes/:remote([0-9]+)" exact component={RemoteContainer} />

          <Redirect path="/" to="/devices" />

          {/* NotFound */}
          <PublicRoute component={NotFoundScene} dummy />

        </Switch>

      </Router>
    );
  }
}

export default App;
