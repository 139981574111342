import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { PublicRoute } from 'routes';

import { UnauthorizedScene, GenericScene, LoginScene } from 'scenes';

import { Auth } from 'services';
import { Typeof } from 'utils';

class PrivateRoute extends PublicRoute {

  getRoute() {

    //User not logged
    if(!Auth.isLogged()) {

      console.log("not logged");


      return (
        <LoginScene from={this.props.location.pathname} />
      );
    }

    //Check role
    let user = Auth.getUser();

    let roles = this.props.roles;

    if(Typeof.string(roles)) {
      roles = [roles];
    }

    if(Typeof.array(roles)) {

      //Fix roles
      roles = roles.map((role) => role.trim());

      let granted = user.roles.some((role) => {
        return roles.includes(role);
      });

      //Not granted
      if(!granted) {
        return (
          <GenericScene dummy>
            <UnauthorizedScene />
          </GenericScene>
        );
      }
    }

    return super.getRoute();
  }
}

PrivateRoute.propTypes = {
  role: PropTypes.arrayOf(PropTypes.string)
};

export default withRouter(PrivateRoute);
