import React from 'react'
import PropTypes from 'prop-types';

import { Route } from "react-router-dom";

import { NotFoundScene } from 'scenes';

import { Auth } from 'services';

class PublicRoute extends React.Component {

  render() {

    return this.getRoute();
  }

  getRoute() {

    //Extract component and the rest of props
    const { component: Content, ...rest } = this.props;

    return (
      <Route {...rest} render={(props) =>
        <Content {...props} />
      } />
    )
  }
}

PublicRoute.defaultProps = {
  target: null,
  navigation: true
};

PublicRoute.propTypes = {
  target: PropTypes.string,
  navigation: PropTypes.bool.isRequired,
  component: PropTypes.element.isRequired
};

export default PublicRoute;
