import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { ModalOptions } from 'modals';
import { ModalCategory, ModalAssociateEntity } from 'modals';

import { GenericScene } from 'scenes'

import { Section, Properties, InfosBar, Table, Empty, Aligner } from 'components';
import { Container, Button, Image, Flag, Message, Icon } from 'semantic-ui-react';

import { API } from 'services';

import { BreadcrumbHelper, Typeof, Functions } from 'utils';
import { EnumLocale } from 'enums';

const PROGRAMS_PER_PAGE = 10;

class CategoryScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      category: props.category,
      showEditModal: false,
      showDeleteModal: false,
      programSearchCriteria: '',
      programToDissociate: null,
      showProgramAssociateModal: false,
      showProgramDissociateModal: false,
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleEditModalClose = this.handleEditModalClose.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);

    this.handleProgramSearch = this.handleProgramSearch.bind(this);

    this.handleProgramSelect = this.handleProgramSelect.bind(this);

    this.toggleProgramAssociateModal = this.toggleProgramAssociateModal.bind(this);
    this.handleProgramAssociateModalClose = this.handleProgramAssociateModalClose.bind(this);

    this.toggleProgramDissociateModal = this.toggleProgramDissociateModal.bind(this);
    this.handleProgramDissociateModalClose = this.handleProgramDissociateModalClose.bind(this);

    this.handleProgramOrderUp = this.handleProgramOrderUp.bind(this);
    this.handleProgramOrderDown = this.handleProgramOrderDown.bind(this);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Administration', '/');
    BreadcrumbHelper.push('Catégories', '/categories');
    BreadcrumbHelper.push(`Catégorie #${this.state.category.id}`);
    BreadcrumbHelper.flush();
  }

  toggleEditModal() {
    this.setState({
      showEditModal: !this.state.showEditModal
    });
  }

  handleEditModalClose(edit) {

    if(Typeof.object(edit)) {

      this.setState({
        category: edit
      }, this.updateBreadcrumb);
    }

    //Hide modal
    this.toggleEditModal();
  }

  toggleDeleteModal() {

    //Show modal
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleDeleteModalClose(result, setProcessing) {

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      API.delete(`/categories/${this.props.category.id}`)
        .then(() => {

          //Go back to categorys list
          this.props.history.push('/categories');
        });

    } else {

      //Hide modal
      this.toggleDeleteModal();
    }
  }

  handleProgramSearch(criteria) {

    this.setState({
      programSearchCriteria: criteria
    });

  }

  handleProgramSelect(program) {
    this.props.history.push(`/programs/${program.id}`);
  }

  toggleProgramAssociateModal() {

    this.setState({
      showProgramAssociateModal: !this.state.showProgramAssociateModal
    });
  }

  handleProgramAssociateModalClose(result, setProcessing, program) {

    let promises = [];

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      promises.push(
        API.put(`/categories/${this.state.category.id}/programs/${program}`)
          .then(() => {

            this.state.category.programs.push(program);
          })
      );

    }

    Promise.all(promises).then(() => this.toggleProgramAssociateModal());
  }

  toggleProgramDissociateModal(program = null, event = null) {

    if(event) {
      event.stopPropagation();
    }

    //Show modal
    this.setState({
      programToDissociate: program,
      showProgramDissociateModal: !this.state.showProgramDissociateModal
    });
  }

  handleProgramDissociateModalClose(result, setProcessing) {

    let promises = [];

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      promises.push(

        API.delete(`/categories/${this.state.category.id}/programs/${this.state.programToDissociate}`)
          .then(() => {

            //Dissociate program
            this.state.category.programs = this.state.category.programs.filter((program) => {
              return program !== this.state.programToDissociate
            });
          })

      );
    }

    Promise.all(promises).then(() => this.toggleProgramDissociateModal(null));
  }

  handleProgramOrderUp(program, event = null) {

    if (event) {
      event.stopPropagation();
    }

    this.reorderProgram(program, -1);
  }

  handleProgramOrderDown(program, event = null) {

    if (event) {
      event.stopPropagation();
    }

    this.reorderProgram(program, 1);
  }

  reorderProgram(program, direction) {

    let originals = this.state.category.programs;
    let programs = [...originals];

    let index = programs.indexOf(program.id);
    let newIndex = index + direction;

    programs.splice(index, 1);
    programs.splice(newIndex, 0, program.id);

    this.setState({
      category: {
        ...this.state.category,
        programs: programs
      }
    });

    API.put(`/categories/${this.state.category.id}/programs/order`, { order: programs })
      .catch(() => {

        //Rollback
        this.setState({
          category: {
            ...this.state.category,
            programs: originals
          }
        });

      })
  }

  render() {

    const { category } = this.state;
    const { programs } = this.props;

    let properties = [
      {
        name: 'Nom',
        value: () => {

          return (
            <Aligner direction="vertical" vertical="bottom" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = category.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>

                    {localization != null && Typeof.string(localization.name) ? (
                      <span>{localization.name}</span>
                    ) : (
                      <Empty />
                    )}

                    <Flag name={locale.flag.toLowerCase()} />
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        name: 'Logo',
        value: () => {

          if(!Typeof.string(category.logo)) {
            return <Empty />;
          }

          return <Image className="logo" src={Functions.base64ToImage(category.logo)} />;
        }
      }
    ];

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (program) => program.id,
        fit: true
      },
      {
        id: 'logo',
        header: 'Logo',
        render: (program) => {

          if(program.logo != null) {
            return <Image src={Functions.base64ToImage(program.logo)} className="logo" />
          } else {
            return <Empty />
          }
        },
        fit: true
      },
      {
        id: 'name',
        header: 'Nom',
        value: (program) => {

          return program.localizations.map((localization) => {
            return localization.name
          });
        },
        render: (program) => {

          return (
            <Aligner direction="vertical" vertical="left" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = program.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>
                    <Flag name={locale.flag.toLowerCase()} />

                    {localization != null && Typeof.string(localization.name) ? (
                      <span>{localization.name}</span>
                    ) : (
                      <Empty />
                    )}
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        id: 'old_name',
        header: 'Ancien nom',
        value: (program) => {

          return program.localizations.map((localization) => {
            return localization.old_name
          });
        },
        render: (program) => {

          return (
            <Aligner direction="vertical" vertical="left" className="name">
              {EnumLocale.values.map((k) => {

                let locale = EnumLocale[k];
                let localization = program.localizations.find((localization) => {
                  return localization.locale == locale.code;
                });

                return (
                  <span key={locale.code}>
                    <Flag name={locale.flag.toLowerCase()} />

                    {localization != null && Typeof.string(localization.old_name) ? (
                      <span>{localization.old_name}</span>
                    ) : (
                      <Empty />
                    )}
                  </span>
                )
              })}
            </Aligner>
          )
        }
      },
      {
        id: 'action',
        header: 'Actions',
        render: (program) => {
          return (
            <Button icon="unlink" size="tiny" color="red" circular onClick={(event) => this.toggleProgramDissociateModal(program.id, event)} />
          );
        },
        fit: true
      },
      {
        id: 'order',
        header: 'Ordre',
        value: (program) => program.index,
        render: (program) => {
          return (
            <Button.Group>
              <Button icon disabled={program.index == 0} onClick={(event) => this.handleProgramOrderUp(program, event)} >
                <Icon name='chevron up' />
              </Button>
              <Button.Or text={program.index + 1} />
              <Button icon disabled={program.index == availablePrograms.length - 1} onClick={(event) => this.handleProgramOrderDown(program, event)} >
                <Icon name='chevron down' />
              </Button>
            </Button.Group>
          )
        },
        fit: true
      }
    ];

    const availablePrograms = category.programs.map((id, index) => {
      let program = programs.find(program => program.id === id);

      return {
        ...program,
        index: index
      }
    });

    return (
      <GenericScene>

        <Container id="category">

          <Section title="Propriétés" actions={[
            <Button icon="edit" circular onClick={this.toggleEditModal} />,
            <Button icon="trash" circular color="red" onClick={this.toggleDeleteModal} />
          ]}>

            <Properties properties={properties} />
          </Section>

        </Container>

        <Container>

          <Section title="Programmes associés" onSearch={this.handleProgramSearch} criteria={this.state.programSearchCriteria} actions={[
            <Button content="Associer" onClick={this.toggleProgramAssociateModal} key={0} />
          ]}>

            {availablePrograms.length > 0 ? (

              <React.Fragment>

                <InfosBar mode="column">

                  {/* Number of associated programs */}
                  <span>{availablePrograms.length > 1 ? `${availablePrograms.length} programmes associés` : '1 programme associé'}</span>

                </InfosBar>

                {/* Rooms list */}
                <Table columns={columns}
                  criteria={this.state.programSearchCriteria}
                  items={availablePrograms}
                  itemsPerPage={PROGRAMS_PER_PAGE}
                  onSelect={this.handleProgramSelect}
                  messageEmpty="Aucun progrmame ne correspond à votre recherche"
                  sort="order"
                  direction="ascending"
                />

              </React.Fragment>

            ) : (

              // No program associated
              <InfosBar mode="row">
                <span>Aucun programme associé</span>

              </InfosBar>

            )}
          </Section>

        </Container>

        {/* Category edit modal */}
        {this.state.showEditModal &&
          <ModalCategory onClose={this.handleEditModalClose} category={category} />
        }

        {/* Category delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleDeleteModalClose} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer cette catégorie.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

        {/* Program association modal */}
        {this.state.showProgramAssociateModal &&
          <ModalAssociateEntity onClose={this.handleProgramAssociateModalClose} entities={programs} associated={category.programs} config={{
            title: "Associer un programme",
            field: "Programme",
            empty: "Aucun programme n'est disponible"
          }} />
        }

        {/* Program dissociation modal */}
        {this.state.showProgramDissociateModal &&
          <ModalOptions onClose={this.handleProgramDissociateModalClose} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de dissocier le programme de cette catégorie.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </GenericScene>
    );
  }
}

export default withRouter(CategoryScene);
