import React from 'react'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { RemoteScene } from 'scenes';

class RemoteContainer extends AbstractContainer {

  getTasks(params) {

    const deviceId = params.device;
    const remoteId = params.remote;

    return [
      {
        key: 'device',
        exec: (done, failure) => {
          API.get(`devices/${deviceId}`)
            .then((response) => {
              let device = {
                ...response.data,
                id: deviceId
              };

              done(device);
            })
            .catch(failure);
        }
      },
      {
        key: 'remote',
        exec: (done, failure) => {
          API.get(`remotes/${remoteId}`)
            .then((response) => {
              let remote = {
                ...response.data,
                id: remoteId
              };

              done(remote);
            })
            .catch(failure);
        }
      },
      {
        key: 'categories',
        exec: (done, failure) => {
          API.get(`categories`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <RemoteScene {...results} />
  }
}

export default RemoteContainer;
