import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { Container, Button } from 'semantic-ui-react';
import { Section, Table, InfosBar, Text } from 'components';

import { GenericScene } from 'scenes';

import { ModalDevice } from 'modals';

import { BreadcrumbHelper, Typeof } from 'utils';

const DEVICES_PER_PAGE = 10;

class DeviceListScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      devices: props.devices,
      criteria: '',
      showCreateModal: false
    };

    this.handleSearch = this.handleSearch.bind(this);

    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleCreateModalClose = this.handleCreateModalClose.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {

    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Appareils installés');
    BreadcrumbHelper.flush();
  }

  handleSearch(criteria) {

    this.setState({
      criteria: criteria
    });
  }

  handleSelect(device) {

    //Navigate to device page
    this.props.history.push(`/devices/${device.id}`);
  }

  toggleCreateModal() {
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  }

  handleCreateModalClose(create) {

    if(Typeof.object(create)) {
      create.remotes = [];

      this.state.devices.push(create);
    }

    this.toggleCreateModal();
  }

  render() {

    const { devices } = this.state;

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (device) => device.id,
        fit: true,
      },
      {
        id: 'name',
        header: 'Nom',
        value: (device) => device.name,
      },
      {
        id: 'address',
        header: 'Adresse',
        value: (device) => `${device.address}, ${device.zip_code} ${device.city}`
      },
      {
        id: 'mac_address',
        header: 'Adresse MAC',
        value: (device) => <Text style="mono uppercase">{device.mac_address}</Text>,
        fit: true
      },
      {
        id: 'remotes',
        header: 'Télécommande(s)',
        value: (device) => device.remotes.length,
        fit: true,
      }
    ];

    return (
      <GenericScene>

        <Container>

          <Section title="Liste des appareils installés" onSearch={this.handleSearch} actions={[

            // Add device
            <Button icon="add" content="Ajouter" onClick={this.toggleCreateModal} />
          ]}>

            {devices.length > 0 ? (

              <React.Fragment>

                {/* Number of available devices */}
                <InfosBar mode="column">
                  <span>{devices.length > 1 ? `${devices.length} appareils disponibles` : '1 appareil disponible'}</span>
                </InfosBar>

                {/* Devices list */}
                <Table columns={columns}
                  criteria={this.state.criteria}
                  items={devices}
                  itemsPerPage={DEVICES_PER_PAGE}
                  onSelect={this.handleSelect}
                  messageEmpty="Aucun appareil ne correspond à votre recherche"
                  sort="id"
                  direction="descending"
                />

              </React.Fragment>

            ) : (

              // No device available
              <InfosBar mode="row">
                <span>Aucune device disponible</span>
              </InfosBar>

            )}

          </Section>

        </Container>

        {/* Modal create customer */}
        {this.state.showCreateModal &&
          <ModalDevice onClose={this.handleCreateModalClose} />
        }

      </GenericScene>
    );
  }
}

export default withRouter(DeviceListScene);
