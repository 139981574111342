import React from 'react'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { ProgramScene } from 'scenes';

class ProgramContainer extends AbstractContainer {

  getTasks(params) {

    const id = params.program;

    return [
      {
        key: 'program',
        exec: (done, failure) => {
          API.get(`programs/${id}`)
            .then((response) => {
              let device = {
                ...response.data,
                id: id
              };

              done(device);
            })
            .catch(failure);
        }
      },
      {
        key: 'categories',
        exec: (done, failure) => {
          API.get('categories')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <ProgramScene {...results} />
  }
}

export default ProgramContainer;
