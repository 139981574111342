import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'

import { renderToString } from 'react-dom/server'

import { Form, Button } from 'semantic-ui-react';

import { Typeof } from 'utils';

import './style.css';

class FormWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      submiting: false,
      violations: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  getInputs() {

    let node = ReactDOM.findDOMNode(this.refs.form);
    return Array.from(node.querySelectorAll('.input-field[name]'));
  }

  handleSuccess() {

    let form = ReactDOM.findDOMNode(this.refs.form);
    form.reset();

    this.setState({
      submiting: false,
      violations: null
    });
  }

  handleError(violations = null) {

    this.setState({
      submiting: false,
      violations: violations
    });

  }

  handleSubmit() {

    let submit = this.props.actions.submit;

    if(Typeof.func(submit.callback)) {

      this.setState({
        submiting: true,
        violations: null
      });

      let data = {};

      let inputs = this.getInputs();
      inputs.forEach((input) => {

        //Process active inputs only
        if(!input.disabled) {

          //The value of the input
          let value = undefined;

          switch (input.tagName.toLowerCase()) {

            case 'textarea':
              value = input.value;
              break;

            case 'input':

              switch (input.type) {

                //Register only checked radios
                case 'radio':

                  if (input.checked) {
                    value = input.value;
                  }
                  break;

                case 'checkbox':

                  value = input.checked;
                  break;

                //Register only filled date
                case 'date':
                case 'date-time':

                  if (input.value.length > 0) {
                    value = input.value
                  }
                  break;

                //Register only non null number
                case 'number':

                  if (input.value.length > 0) {
                    value = Number.parseFloat(input.value)
                  }
                  break;

                //Register input value
                default:

                  value = input.value;
                  break;
              }

              break;

            case 'select':

              //Process multiple select
              if (input.multiple) {

                //Filter selected options
                let options = Array.from(input.options).filter((option) => {
                  return option.selected;
                });

                //Get value of the option
                value = options.map((option) => {
                  return option.value;
                });

              } else {
                value = input.value;
              }

              break;

            default:

              if(input.classList.contains('input-field')) {
                value = input.getAttribute('value');
              }

              break;
          }

          let name = input.getAttribute('name');

          //Register valid values (not blank) and only once
          if (!data.hasOwnProperty(name) && Typeof.defined(value)) {
            data[name] = value;
          }
        }
      });

      submit.callback(data, this.handleSuccess, this.handleError);
    }
  }

  componentDidMount() {

    let node = ReactDOM.findDOMNode(this.refs.form);
    let inputsCandidates = node.querySelectorAll('*[name]');

    inputsCandidates.forEach((input) => {

      //Register
      input.classList.add('input-field');

      //Add feedback
      input.insertAdjacentHTML('afterend', renderToString(
        <span className="feedback" target={input.getAttribute('name')}></span>
      ));

    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.violations !== this.state.violations) {

      let violations = this.state.violations;

      this.getInputs().forEach((input) => {

        let name = input.getAttribute('name');
        let feedback = input.parentNode.querySelector(`.feedback[target="${name}"]`);

        if(feedback !== null) {
          let hasViolations = Typeof.object(violations) && violations.hasOwnProperty(name);
          let message = hasViolations ? violations[name].join('<br />') : '';

          input.classList.toggle('invalid', hasViolations);
          feedback.innerHTML = message;
        }
      });

    }
  }

  render() {

    let actions = [];

    if(Typeof.object(this.props.actions)) {

      //Submit
      if(Typeof.object(this.props.actions.submit)) {
        let actionSubmit = this.props.actions.submit;

        let name = Typeof.get(Typeof.string, actionSubmit.name, 'Valider');
        let active = Typeof.get(Typeof.bool, actionSubmit.active, true);

        actions.push(
          <Button type="submit" content={name} loading={this.state.submiting} disabled={!active || this.state.submiting} key="submit" />
        );
      }

      //Cancel
      if(Typeof.object(this.props.actions.cancel)) {
        let actionCancel = this.props.actions.cancel;

        let name = Typeof.get(Typeof.string, actionCancel.name, 'Annuler');
        let callback = Typeof.get(Typeof.func, actionCancel.callback, undefined);
        let active = Typeof.get(Typeof.bool, actionCancel.active, true);

        actions.push(
          <Button type="reset" content={name} onClick={() => callback()} disabled={!active || this.state.submiting} key="cancel" />
        );
      }

    }

    return (
      <Form ref="form" onSubmit={this.handleSubmit} >
        {this.props.children}

        {/* Actions */}
        <div className="actions">
          {actions}
        </div>

      </Form>
    );
  }
}

let actionShape = PropTypes.shape({
  button: PropTypes.string,
  callback: PropTypes.func
});

FormWrapper.propTypes = {
  actions: PropTypes.shape({
    submit: actionShape,
    callback: actionShape
  })
};

export default FormWrapper;
