import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';

import './style.css';

class Aligner extends React.Component {

  render() {

    let classes = classNames(
      'aligner',
      this.props.direction,
      `v-${this.props.vertical}`,
      `h-${this.props.horizontal}`,
      this.props.className
    );

    return (
      <div className={classes}>
        {this.props.children}
      </div>
    );
  }
}

Aligner.defaultProps = {
  direction: 'horizontal',
  vertical: 'center',
  horizontal: 'center'
};

Aligner.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
  horizontal: PropTypes.oneOf(['left', 'center', 'right', 'space-between'])
};

export default Aligner;
