import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Header, TransitionablePortal, Button, Icon } from 'semantic-ui-react'

class ModalOptions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: null,
      disabled: null
    };

    this.setProcessing = this.setProcessing.bind(this);
  }

  setProcessing(value) {

    this.setState({
      processing: value
    });

  }

  setDisabled(value, flag) {
    this.setState({
      disabled: flag ? value : null
    });
  }

  render() {

    let options = [];

    this.props.options.forEach((option, index) => {

      options.push(
        <Button key={index} color={option.color} onClick={() => this.props.onClose(option.value, this.setProcessing)} loading={this.state.processing === option.value} disabled={this.state.processing || option.disabled} >

          {/* Icon */}
          {option.icon &&
            <Icon name={option.icon} />
          }

          {/* Name */}
          {option.name}

        </Button>
      );

    });

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.props.icon} content={this.props.title} />
          <Modal.Content>
            {this.props.message}
            {this.props.children}
          </Modal.Content>

          {/* Options */}
          {options.length > 0 &&
            <Modal.Actions>
              {options}
            </Modal.Actions>
          }

        </Modal>

      </TransitionablePortal>
    );
  }
}

ModalOptions.OPTION_YES    = 'yes';
ModalOptions.OPTION_CANCEL = 'cancel';

ModalOptions.OPTIONS_YESCANCEL = [
  {
    name: 'Oui',
    icon: 'checkmark',
    color: 'red',
    value: ModalOptions.OPTION_YES
  },
  {
    name: 'Annuler',
    icon: 'remove',
    value: ModalOptions.OPTION_CANCEL
  }
];

ModalOptions.defaultProps = {
  options: []
};

ModalOptions.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.node,
  message: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    value: PropTypes.string.isRequired
  })).isRequired
};

export default ModalOptions;
