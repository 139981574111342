let Values = {

  FRENCH: {
    code: 'fr_FR',
    flag: 'FR',
    name: 'Français'
  },

  ENGLISH: {
    code: 'en_EN',
    flag: 'GB',
    name: 'Anglais'
  }
};

export default Object.seal({
  ...Values,

  /* Default value */
  default: Values.FRENCH,

  /* All values */
  values: Object.keys(Values)
});
