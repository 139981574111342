let Periodicity = {
  HOURLY: 'HOURLY',
  H_DAILY: 'H_DAILY',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  H_YEARLY: 'H_YEARLY',
  YEARLY: 'YEARLY'
};

let MediaCategory = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  MODEL: 'MODEL'
};

Periodicity.values = Object.keys(Periodicity);

Periodicity.name = (value) => {
  switch(value) {
    case Periodicity.HOURLY:
      return 'Horaire';
    case Periodicity.H_DAILY:
      return 'Demi-journée';
    case Periodicity.DAILY:
      return 'Journalier';
    case Periodicity.WEEKLY:
      return 'Hebdomadaire';
    case Periodicity.MONTHLY:
      return 'Mensuel';
    case Periodicity.QUARTERLY:
      return 'Trimestriel';
    case Periodicity.H_YEARLY:
      return 'Semestriel';
    case Periodicity.YEARLY:
      return 'Annuel';
  }
};

let Enums = {
  Periodicity,
  MediaCategory
};

export default Object.seal(Enums);
