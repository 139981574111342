import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, Text } from 'components';

import { Form, Modal, Header, TransitionablePortal, Icon, Label, Divider, Grid, Flag, Tab, Menu } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof, Functions } from 'utils';

import { EnumLocale } from 'enums';

import './style.css';

class ModalCategory extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.isEdit = Typeof.object(props.category);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: props.onClose
        }
      },

      icon: 'add',
      title: 'Nouvelle catégorie',
      category: {},
      method: API.post,
      route: '/categories'
    };

    if (this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification catégorie',
        category: props.category,
        method: API.put,
        route: `/categories/${props.category.id}`
      };
    }

    this.state = state;
  }

  handleSubmit(data, onSucess, onError) {

    let promises = [];

    let update = () => {

      //Localization
      EnumLocale.values.map((k) => {

        let locale = EnumLocale[k];

        let name = data[`${locale.code}:name`].trim();

        promises.push(new Promise((resolve, reject) => {

          //Localize
          API.put(`/categories/${this.state.category.id}/localize`, {
            locale: locale.code,
            name: name
          })
          .then(resolve)
          .catch(reject);

        }));

      });

      //Logo
      let files = this.refs.logo.files;

      if(files.length > 0) {

        promises.push(new Promise((resolve, reject) => {

          let data = new FormData();
          data.append('file', files[0]);

          API.post(`/categories/${this.state.category.id}/logo`, data)
            .then(resolve)
            .catch(reject);
        }));
      }

      Promise.all(promises)
        .then(() => {

          API.get(`/categories/${this.state.category.id}`)
            .then((response) => {

              this.props.onClose({
                id: this.state.category.id,
                ...response.data
              });
            });

        })
        .catch((error) => {
          onError(API.violations(error));
        });
    };

    //Create
    if(!this.isEdit) {

      promises.push(
        API.post('/categories', {})
          .then((response) => {

            this.setState({
              category: response.data
            }, update);
          })
      );

    } else {
      update()
    }
  }

  render() {


    let panes = EnumLocale.values.map((k) => {

      let locale = EnumLocale[k];
      let localization = Typeof.object(this.state.category.localizations) ? this.state.category.localizations.find((localization) => {
        return localization.locale == locale.code;
      }) : null;

      return {
        menuItem: (
          <Menu.Item key={locale.code}>
            <Flag name={locale.flag.toLowerCase()} />
            {locale.name}
          </Menu.Item>
        ),
        pane: (
          <Tab.Pane className="localizable">

            {/* Name */}
            <Form.Field>
              <label htmlFor={`inputName_${locale.code}`}>Nom</label>
              <Input id={`inputName_${locale.code}`} name={`${locale.code}:name`} value={localization != null ? localization.name : null} />
            </Form.Field>

          </Tab.Pane>
        )
      }
    });
    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              {/* Logo */}
              <Form.Field>
                <label htmlFor="inputLogo">Logo</label>
                <input type="file" id="inputMedia" ref="logo" onChange={this.onMediaSet} accept="image/png,image/jpeg" multiple={false} />
              </Form.Field>

              <Tab menu={{ borderless: true, secondary: true, pointing: true }} panes={panes} renderActiveOnly={false} />

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalCategory.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalCategory;
